<template>
  <div class="w-full grid grid-cols-8 lg:grid-cols-3 gap-1 lg:gap-3 md:gap-3">
    <Tab
      :title="'Cart'"
      :subtitle="'Your Cart Items'"
      :icon="'cart'"
      :active="1 === active"
      :activeTab="active"
      :input="1"
      :onclick="onClick"
    />
    <div class="flex pt-6 justify-center lg:hidden">
      <chevron-right-icon size="2x" style="color: #0e4333"></chevron-right-icon>
    </div>
    <Tab
      :title="'Address'"
      :subtitle="'Enter Your Address'"
      :icon="'home'"
      :active="2 === active"
      :activeTab="active"
      :input="2"
      :onclick="onClick"
    />
    <div class="flex pt-6 justify-center lg:hidden">
      <chevron-right-icon size="2x" style="color: #0e4333"></chevron-right-icon>
    </div>
    <Tab
      :title="'Payment'"
      :subtitle="'Enter Your Payment'"
      :icon="'payment'"
      :active="3 === active"
      :activeTab="active"
      :input="3"
      :onclick="onClick"
      :hideArrow="true"
    />
  </div>
</template>
<script>
import Tab from "./tab.vue";
import { ChevronRightIcon } from "vue-feather-icons";
export default {
  name: "Tabs",
  components: {
    Tab,
    ChevronRightIcon,
  },
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    active: {
      type: Number,
      required: true,
      default: 1,
    },
  },
};
</script>
