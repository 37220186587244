<template>
  <div
    class="
      p-2
      flex flex-col
      lg:flex-row
      items-center
      col-span-2
      lg:col-span-1
    "
    :class="active || (input <= activeTab) ? 'cusor-pointer' : 'pointer-events-none'"
    @click="onclick(input)"
  >
    <div :class="active ? activebg : inactivebg">
      <shopping-cart-icon
        v-if="icon === 'cart'"
        :style="!active && 'color:#b0b0b0'"
      />
      <home-icon v-if="icon === 'home'" :style="!active && 'color:#b0b0b0'" />
      <credit-card-icon
        v-if="icon === 'payment'"
        :style="!active && 'color:#b0b0b0'"
      />
    </div>
    
    <div
      class="
        flex flex-col
        lg:h-16 lg:justify-between
        md:justify-center
        lg:pl-4
        py-1
      "
    >
      <p
        class="
          pt-2
          lg:pt-0
          md:pt-0
          text-base
          lg:text-lg
          md:text-lg
          font-semibold
          text-center
          lg:text-left
          md:text-left
        "
        :style="!active && 'color:#b0b0b0'"
      >
        {{ title }}
      </p>
      <p
        class="
          hidden
          lg:inline-block
          text-xs
          lg:text-base
          md:text-base
          font-semibold
          text-center
          lg:text-left
          md:text-left
        "
        style="color: #b0b0b0"
      >
        {{ subtitle }}
      </p>
    </div>
    <div
      class="
        hidden
        lg:flex
        flex-col
        h-16
        justify-center
        items-center
        lg:pl-10
        py-1
      "
      v-if="!hideArrow"
    >
      <chevron-right-icon size="2x" style="color: #0e4333"></chevron-right-icon>
    </div>
  </div>
</template>
<script>
import {
  ShoppingCartIcon,
  ChevronRightIcon,
  HomeIcon,
  CreditCardIcon,
} from "vue-feather-icons";
export default {
  name: "Tab",
  data() {
    return {
      activebg:
        "text-xs w-16 h-16 flex items-center bg-primary text-white justify-center rounded shadow-md",
      inactivebg:
        "text-xs w-16 h-16 flex items-center bg-gray-100 text-gray-300 justify-center rounded",
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    onclick: {
      type: Function,
      required: true,
    },
    input: {
      type: Number,
      required: true,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: 0
    }
  },
  watch: {
    activ: function (val) {
      if (val) {
        this.color =
          "w-16 h-16 bg-primary flex items-center justify-center rounded shadow-md";
      }
    },
  },
  components: {
    ShoppingCartIcon,
    ChevronRightIcon,
    HomeIcon,
    CreditCardIcon,
  },
};
</script>